<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t('common.filters') }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4">
          <!-- countryCode -->
          <enum-select
            id="country-code-filter"
            v-model="countryCode"
            :label="$t('common.country')"
            enum-class="CountryCode"
            :overlay="offersLoading"
            text="text"
          />
        </b-col>
        <b-col cols="12" md="4">
          <!-- offerActive -->
          <b-form-group :label="$t('common.active')" label-for="offer-active-filter">
            <b-overlay :show="offersLoading">
              <v-select
                id="offer-active-filter"
                v-model="offerActive"
                :options="[
                  { label: $t('common.yes'), value: true },
                  { label: $t('common.no'), value: false },
                ]"
                :reduce="name => name.value"
                :placeholder="$t('common.active')"
                clearable
              >
                <template #no-options> {{ $t('common.no_option_found') }} </template>
              </v-select>
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <!-- offerType -->
          <enum-select
            id="offer-type-filter"
            v-model="offerType"
            :label="$t('common.type')"
            enum-class="OfferType"
            :overlay="offersLoading"
          />
        </b-col>
        <b-col cols="12" md="4">
          <!-- organization -->
          <b-form-group :label="$t('offer.organization')" label-for="organization-filter">
            <b-overlay :show="organizationsLightLoading || offersLoading">
              <v-select
                id="organization-filter"
                v-model="subscriberId"
                :options="organizationsLight"
                :reduce="name => name.id"
                label="legalName"
                :placeholder="$t('offer.organization')"
                clearable
              >
                <template #no-options> {{ $t('common.no_option_found') }} </template>
              </v-select>
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <!-- mainTypology -->
          <b-form-group :label="$t('offer.main_typology')" label-for="main-typology-filter">
            <b-overlay :show="organizationTypologiesLoading || offersLoading">
              <v-select
                id="main-typology-filter"
                v-model="mainTypologyId"
                :options="organizationTypologies"
                :reduce="name => name.id"
                label="name"
                :placeholder="$t('offer.main_typology')"
                clearable
              >
                <template #option="{ name }">
                  {{ name | trans }}
                </template>
                <template #selected-option="{ name }">
                  {{ name | trans }}
                </template>
                <template #no-options> {{ $t('common.no_option_found') }} </template>
              </v-select>
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex align-items-center justify-content-end">
          <!-- :disabled="filtersOptionsLoading || offersLoading || countryCodeLoading" -->
          <b-button
            id="action-reset-filter"
            :disabled="!subscriberId && !mainTypologyId && !countryCode && !offerActive && !offerType"
            size="sm"
            variant="outline-secondary"
            class="mr-1"
            @click="clearFilters()"
          >
            {{ $t('action.clear_all') }}
          </b-button>
          <b-button id="action-filter" v-ripple.400 :disabled="!filtersChanged" size="sm" @click="fetchOffers(true), (filtersChanged = false)">
            {{ $t('action.filter') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'OfferFilters',

  data() {
    return {
      filtersChanged: false,
    }
  },

  computed: {
    ...mapState('offer', ['filter', 'filtersOptionsLoading', 'offersLoading']),
    ...mapState('organization', ['organizationsLight', 'organizationsLightLoading']),
    ...mapState('organizationConfiguration', ['organizationTypologies', 'organizationTypologiesLoading']),

    ...mapFields('offer', ['filter.subscriberId', 'filter.mainTypologyId', 'filter.countryCode', 'filter.offerActive', 'filter.offerType']),
  },

  watch: {
    filter: {
      handler() {
        this.filtersChanged = true
      },
      deep: true,
    },
  },

  mounted() {
    this.fetchOrganizationsLight()
    this.fetchOrganizationConfigurationTypologies()
  },

  // organization light
  methods: {
    ...mapActions('offer', ['fetchOffers', 'clearFilters']),
    ...mapActions('organization', ['fetchOrganizationsLight']),
    ...mapActions('organizationConfiguration', ['fetchOrganizationConfigurationTypologies']),
  },
}
</script>
