<template>
  <div id="offer-informations-light">
    <app-sidebar-form
      :disabled="offerLoading"
      :edition="offerEdition"
      :edition-mode-title="offerEditionMode"
      :entity="offer"
      :title="$tc('offer.title', 1)"
      form-name="offer-informations-light"
      no-pristine
      @update:edition="$emit('editionUpdated', $event)"
      @update:entity="$emit('offerUpdated', $event)"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <!-- reference-->
            <app-form-text-input-itn
              v-model="data.item.reference"
              :default-lang="$store.state.auth.defaultLang"
              :label="$t('offer.reference')"
              :languages="$store.state.auth.activelang"
              :loading="offerLoading"
              form-name="offer-informations-light"
            />
          </b-col>
          <b-col cols="12" md="8">
            <!-- main-typology -->
            <validation-provider #default="{ errors }" :name="$t('offer.main_typology')" rules="required">
              <b-form-group :label="$t('offer.main_typology')" class="validation-required" label-for="offer-informations-light-main-typology">
                <b-overlay :show="offerLoading || organizationTypologiesLoading">
                  <v-select
                    v-model="data.item.mainTypology.id"
                    :clearable="false"
                    :get-option-label="opt => $options.filters.trans(opt.name)"
                    :options="organizationTypologies"
                    :placeholder="$t('offer.main_typology')"
                    :reduce="name => name.id"
                    class="select-size-md"
                    input-id="offer-informations-light-main-typology"
                  >
                    <template #no-options>
                      {{ $t('common.no_option_found') }}
                    </template>
                  </v-select>
                </b-overlay>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <!-- offerType -->
            <enum-select
              id="offer-informations-light-type"
              v-model="data.item.type"
              :label="$t('common.type')"
              enum-class="OfferType"
              :overlay="offerLoading"
              :clearable="false"
              required
            >
              <template #option="{ text, value }">
                <b-badge v-if="value === 'standard'" pill variant="light-success">
                  {{ text }}
                </b-badge>
                <b-badge v-else-if="value === 'custom'" pill variant="light-info">
                  {{ text }}
                </b-badge>
              </template>
              <template #selected-option="{ text, value }">
                <b-badge v-if="value === 'standard'" pill variant="light-success">
                  {{ text }}
                </b-badge>
                <b-badge v-else-if="value === 'custom'" pill variant="light-info">
                  {{ text }}
                </b-badge>
              </template>
            </enum-select>
          </b-col>
          <b-col cols="12">
            <!-- internalDescription -->
            <validation-provider #default="{ errors }" :name="$t('offer.internal_description')" rules="">
              <b-form-group :label="$t('offer.internal_description')" label-for="offer-internal-description">
                <b-overlay :show="offerLoading">
                  <b-form-textarea
                    id="offer-informations-light-internal-description"
                    v-model="data.item.internalDescription"
                    :placeholder="$t('offer.internal_description')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-overlay>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <!-- icon -->
            <!-- <app-axios-drop-zone
              id="offer-informations-light-icon"
              :label="$t('offer.icon')"
              :loading="offerLoading"
              rules="required"
            /> -->

            <b-form-group :label="$t('offer.icon')" label-for="offer-icon">
              <b-overlay :show="offerLoading || dropZoneLoading" spinner-medium>
                <app-drop-zone
                  id="offer-informations-light-icon"
                  ref="offer-informations-light-icon"
                  :options="dropzoneOptions"
                  @vdropzone-queue-complete="iconUploaded = true"
                  @vdropzone-removed-file="removeOfferIcon()"
                  @vdropzone-file-added="sendOfferIconFile($event)"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { postFileRequest } from '@/request/globalApi/requests/fileRequests'

import AppDropZone from '@/components/AppDropZone.vue'
import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'OfferInformationsLight',

  components: {
    AppDropZone,
    AppFormTextInputItn,
    AppSidebarForm,
  },

  props: {
    offerEdition: {
      type: Boolean,
      required: true,
    },
    offerEditionMode: {
      type: String,
      default: 'show',
    },
    offer: {
      type: Object,
      default: () => ({}),
    },
    offerLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      iconUploaded: true,
      dropZoneLoading: false,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('offer.icon_upload'),
        thumbnailWidth: 250,
        maxFiles: 1,
        maxFilesize: 2000000,
        acceptedFiles: 'image/png,image/jpeg',
        addRemoveLinks: true,
        clickable: true,
      },
    }
  },

  computed: {
    ...mapState('organizationConfiguration', ['organizationTypologies', 'organizationTypologiesLoading']),
  },

  watch: {
    'offer.termsFileUrl': {
      if(value) {
        const file = { size: 2000000, name: this.offer.termsFilePath, type: '' }
        this.$refs['offer-informations-light-icon'].manuallyAddFile(file, value)
      },
    },
  },

  mounted() {
    this.fetchOrganizationConfigurationTypologies()
  },

  methods: {
    ...mapActions('organizationConfiguration', ['fetchOrganizationConfigurationTypologies']),

    removeOfferIcon() {
      this.offer.edited.iconFileId = null
      this.offer.edited.deleteOldIconFile = true
      delete this.offer.termsFilePath
      delete this.offer.termsFileUrl
    },

    sendOfferIconFile(file) {
      if (file.size > this.dropzoneOptions.maxFilesize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('file.max_file_size_reached'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.$refs['offer-informations-light-icon'].removeFile(file)
        return
      }

      const response = {}
      if (!this.offer.termsFileUrl) {
        this.dropZoneLoading = true
        this.iconUploaded = false
        const formData = new FormData()
        formData.append('temporary_file', file)
        postFileRequest(formData)
          // TODO: rename response because already declared
          .then(response => {
            response.iconFileId = response.data.id
            response.deleteOldIconFile = false
          })
          .finally(() => {
            this.iconUploaded = true
            this.dropZoneLoading = false
          })
      }
      return response
    },
  },
}
</script>
