var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"offers"}},[_c('OffersFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}]}),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('app-data-table-top',{attrs:{"table-name":"quotes","show-per-page":_vm.$can('OPERATOR_SUBSCRIPTION_VIEW'),"show-search":_vm.$can('OPERATOR_SUBSCRIPTION_VIEW'),"show-filter":_vm.$can('OPERATOR_SUBSCRIPTION_VIEW'),"show-add":_vm.$can('OPERATOR_SUBSCRIPTION_ADD'),"disabled":_vm.offersLoading,"per-page":_vm.numberOfItemsPerPage,"per-page-options":_vm.dataTable.parPageOption,"search-filter-options":_vm.searchFilterOptions,"search-default-filter":"offerReference","filters-count":_vm.filtersCount,"condensed":"lg","size":"md"},on:{"table-select-per-page":function($event){;(_vm.numberOfItemsPerPage = $event), _vm.fetchOffers(true)},"table-search-text":function($event){;(_vm.searchText = $event), _vm.fetchOffers(true)},"table-search-filter":function($event){;(_vm.searchFilterSelection = $event), _vm.fetchOffers(true)},"table-filter":function($event){_vm.showFilters = $event},"table-add":function($event){return _vm.addOffer()}}})],1),_c('b-table',{ref:"offers-table",staticClass:"position-relative table-dropdown-action",attrs:{"primary-key":"id","items":_vm.offers,"fields":_vm.offersFields,"empty-text":_vm.$t('common.no_record_found'),"busy":_vm.offersLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-local-sorting":"","no-sort-reset":"","hover":"","show-empty":"","responsive":""},on:{"sort-changed":_vm.updateSort},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(id)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
            name: 'offerInformationsShow',
            params: { id: data.item.id, editionMode: 'show' },
          }}},[_vm._v(" #"+_vm._s(data.item.id)+" ")])]}},{key:"cell(offerReference)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("trans")(data.item.reference))+" ")]}},{key:"cell(mainTypology)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("trans")(data.item.mainTypology.name))+" ")]}},{key:"cell(offerType)",fn:function(data){return [_c('span',{class:{
            'custom-type': data.item.type === 'custom',
            'standard-type': data.item.type === 'standard',
          }},[_vm._v(" "+_vm._s(_vm._f("enumTranslate")(data.item.type,'offer_type'))+" ")])]}},{key:"cell(lastUpdate)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("dateFormatUtc")(data.item.lastUpdate))+" ")])]}},{key:"cell(validityDate)",fn:function(data){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("dateFormatUtc")(data.item.validityDate))+" ")])]}},{key:"cell(reconductible)",fn:function(data){return [(data.item.reconductible)?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.$t('common.yes'))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" "+_vm._s(_vm.$t('common.no')))])]}},{key:"cell(subscribersNumber)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(data.item.subscribersNumber)+" ")])]}},{key:"cell(active)",fn:function(data){return [(data.item.active)?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.$t('common.yes'))+" ")]):_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" "+_vm._s(_vm.$t('common.no')))])]}},{key:"cell(actions)",fn:function(data){return [_c('app-data-table-actions',{attrs:{"table-name":'offers' + data.item.id,"show-show":_vm.$can('OPERATOR_SUBSCRIPTION_VIEW') && data.item.subscribersNumber > 0,"show-duplicate":_vm.$can('OPERATOR_SUBSCRIPTION_ADD'),"show-edit":_vm.$can('OPERATOR_SUBSCRIPTION_EDIT') && data.item.subscribersNumber === 0,"show-delete":_vm.$can('OPERATOR_SUBSCRIPTION_DELETE')},on:{"show":function($event){return _vm.$router.push({
              name: 'offerInformationsShow',
              params: {
                id: data.item.id,
                editionMode: 'show',
                subscribersNumber: data.item.subscribersNumber,
              },
            })},"duplicate":function($event){return _vm.duplicateOffer(data.item)},"edit":function($event){return _vm.$router.push({
              name: 'offerInformationsEdit',
              params: {
                id: data.item.id,
                editionMode: 'edit',
                subscribersNumber: data.item.subscribersNumber,
              },
            })},"delete":function($event){data.item.subscribersNumber === 0 ? _vm.softDeleteOffer(data.item) : _vm.toggleSoftDelete(data.item)}}})]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('app-data-table-bottom',{attrs:{"table-name":"offers","page":_vm.page,"per-page":_vm.numberOfItemsPerPage,"total-count":_vm.pagination.totalItems},on:{"pagination":function($event){;(_vm.page = $event), _vm.fetchOffers(true)}}})],1)],1),_c('OfferInformationsLight',{attrs:{"offer-edition":_vm.offerEdition,"offer-edition-mode":_vm.offerEditionMode,"offer":_vm.selectedOffer,"offer-loading":_vm.offersLoading},on:{"offerUpdated":function($event){return _vm.postOffer($event)},"editionUpdated":function($event){_vm.offerEdition = $event}}}),_c('OfferDeletionSoft',{attrs:{"offer-deletion":_vm.offerDeletion,"offer-deletion-mode":_vm.offerDeletionMode,"offer":_vm.reaffectedOffer,"offer-loading":_vm.offersLoading},on:{"offerUpdated":function($event){return _vm.softDeleteOffer($event)},"editionUpdated":function($event){_vm.offerDeletion = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }